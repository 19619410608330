let BASE_URL,
  SECOND_URL,
  SOCKET_BASE_URL,
  ENVIRONMENT = "dev",
  DOMAIN_URL_TEMP = "https://gaming-stars.4-com.pro",
  TWITCH_URL_TEMP =
    "https://id.twitch.tv/oauth2/authorize?client_id=88pydm8fpnevlb7dks1jmi5qknagl8&redirect_uri=http%3A%2F%2Fgaming-stars.4-com.pro%2Fauth%2Ftwitch&response_type=token&scope=user_read";

if (localStorage.getItem("BASE_URL")) {
  BASE_URL = localStorage.getItem("BASE_URL");
} else if (window.location.host.includes("localhost")) {
  BASE_URL = "https://api.gaming-stars.4-com.pro/api/v0";
} else if (window.location.host === "gaming-stars.4-com.pro") {
  BASE_URL = "https://api.gaming-stars.4-com.pro/api/v0";
} else if (window.location.host === "app.gaming-stars.net") {
  BASE_URL = "https://api.gaming-stars.net/api/v0";
  ENVIRONMENT = "live";
  DOMAIN_URL_TEMP = "https://app.gaming-stars.net";
  TWITCH_URL_TEMP =
    "https://id.twitch.tv/oauth2/authorize?client_id=88pydm8fpnevlb7dks1jmi5qknagl8&redirect_uri=https%3A%2F%2Fapp.gaming-stars.net%2Fauth%2Ftwitch&response_type=token&scope=user_read";
} else {
  BASE_URL = "https://api.prelive.gaming-stars.net/api/v0";
  ENVIRONMENT = "prelive";
  DOMAIN_URL_TEMP = "https://app.prelive.gaming-stars.net";
  TWITCH_URL_TEMP =
    "https://id.twitch.tv/oauth2/authorize?client_id=88pydm8fpnevlb7dks1jmi5qknagl8&redirect_uri=https%3A%2F%2Fapp.prelive.gaming-stars.net%2Fauth%2Ftwitch&response_type=token&scope=user_read";
}

if (localStorage.getItem("SOCKET_BASE_URL")) {
  SOCKET_BASE_URL = localStorage.getItem("SOCKET_BASE_URL");
} else if (window.location.host.includes("localhost")) {
  SOCKET_BASE_URL = "wss://api.gaming-stars.4-com.pro/api/v0";
} else if (window.location.host === "gaming-stars.4-com.pro") {
  SOCKET_BASE_URL = "wss://api.gaming-stars.4-com.pro/api/v0";
} else if (window.location.host === "app.gaming-stars.net") {
  SOCKET_BASE_URL = "wss://api.gaming-stars.net/api/v0";
} else {
  SOCKET_BASE_URL = "wss://api.prelive.gaming-stars.net/api/v0";
}

SECOND_URL = "";
export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
export const API_SOCKET_BASE_URL = SOCKET_BASE_URL;
export const DEV_ENV = ENVIRONMENT === "dev";
export const LIVE_ENV = ENVIRONMENT === "live";
export const DOMAIN_URL = DOMAIN_URL_TEMP;
export const FACEBOOK_APP_ID = ENVIRONMENT === "dev" ? "2318848598348518" : "542668380162386";
export const TWITCH_URL = TWITCH_URL_TEMP;
export const GOOGLE_CLIENT_ID =
  ENVIRONMENT === "dev"
    ? "153537408042-ojhqr39duip73335qn0ku25g4rp62qm9.apps.googleusercontent.com"
    : "153537408042-ojhqr39duip73335qn0ku25g4rp62qm9.apps.googleusercontent.com";
export const TWITTER_URL = "https://twitter.com/gamingstarsnet";
export const INSTAGRAM_URL = "https://www.instagram.com/gamingstars.official/?hl=en";
export const DISCORD_URL = "https://discord.gg/ARK8armszw";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCLJPjDqP9BFQmPb0_yA27jQ/featured";
export const HCAPTCHA_SITE_KEY = "bab28ab8-3a43-45a7-8fe8-47899bcbb361";
