//authentication actions

export const LOGIN = "LOGIN";

export const LOGOUT = "LOGOUT";

export const REGISTER = "REGISTER";

export const REGISTER_CONFIRM = "REGISTER_CONFIRM";

export const RECOVERY_EMAIL = "RECOVERY_EMAIL";

export const RECOVERY_CONFIRM = "RECOVERY_CONFIRM";

export const RECOVERY_NEW_PASSWORD = "RECOVERY_NEW_PASSWORD";

export const RESEND_CODE = "RESEND_CODE";

export const LOGIN_TWITCH = "LOGIN_TWITCH";

export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";

export const LOGIN_GOOGLE = "LOGIN_GOOGLE";

export const FACEBOOK_EMAIL = "FACEBOOK_EMAIL";

export const FACEBOOK_EMAIL_CONFIRM = "FACEBOOK_EMAIL_CONFIRM";

export const SOCIAL_EMAIL_PHONE = "SOCIAL_EMAIL_PHONE";

export const SOCIAL_EMAIL_PHONE_CONFIRM = "SOCIAL_EMAIL_PHONE_CONFIRM";

export const GET_ENTERED_EMAIL = "GET_ENTERED_EMAIL";

export const GET_ENTERED_PHONE = "GET_ENTERED_PHONE";

export const GET_SIGNUP_OPTIONS = "GET_SIGNUP_OPTIONS";

export const POST_NEW_EMAIL = "POST_NEW_EMAIL";

export const POST_NEW_PHONE = "POST_NEW_PHONE";

//profile actions

export const PROFILE = "PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAIL = "PROFILE_FAIL";

export const PROFILE_PLATFORM = "PROFILE_PLATFORM";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

//user actions

export const MY_PROFILE = "MY_PROFILE";
export const MY_PROFILE_SUCCESS = "MY_PROFILE_SUCCESS";
export const MY_PROFILE_FAIL = "MY_PROFILE_FAIL";

export const GET_GLOBAL_SETTINGS = "GET_GLOBAL_SETTINGS";
export const GET_GLOBAL_SETTINGS_SUCCESS = "GET_GLOBAL_SETTINGS_SUCCESS";

export const EDIT_MY_PROFILE = "EDIT_MY_PROFILE";
export const EDIT_MY_PROFILE_SUCCESS = "EDIT_MY_PROFILE_SUCCESS";
export const EDIT_MY_PROFILE_FAIL = "EDIT_MY_PROFILE_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const PREFERENCES = "PREFERENCES";
export const PREFERENCES_SUCCESS = "PREFERENCES_SUCCESS";
export const PREFERENCES_FAIL = "PREFERENCES_FAIL";

export const SET_PREFERENCES = "SET_PREFERENCES";
export const SET_PREFERENCES_SUCCESS = "SET_PREFERENCES_SUCCESS";
export const SET_PREFERENCES_FAIL = "SET_PREFERENCES_FAIL";

export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const POST_REPORT_USER = "POST_REPORT_USER";
export const PUT_ID_FOR_REPORT = "PUT_ID_FOR_REPORT";
export const RESET_ID_FOR_REPORT = "RESET_ID_FOR_REPORT";

export const POST_CHANGE_PHONE = "POST_CHANGE_PHONE";
export const POST_CHANGE_PHONE_CONFIRM = "POST_CHANGE_PHONE_CONFIRM";
export const POST_RESEND_PHONE_CODE = "POST_RESEND_PHONE_CODE";

export const POST_DO_NOT_SHOW_BONUS_OFFER = "POST_DO_NOT_SHOW_BONUS_OFFER";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

//leaderboard actions

export const LEADERBOARD = "LEADERBOARD";
export const LEADERBOARD_SUCCESS = "LEADERBOARD_SUCCESS";
export const LEADERBOARD_FAIL = "LEADERBOARD_FAIL";

//friends actions

export const FRIENDS_LIST = "FRIENDS_LIST";
export const FRIENDS_LIST_SUCCESS = "FRIENDS_LIST_SUCCESS";
export const FRIENDS_LIST_FAIL = "FRIENDS_LIST_FAIL";

export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAIL = "SEARCH_USERS_FAIL";

export const SEARCH_FRIENDS = "SEARCH_FRIENDS";
export const SEARCH_FRIENDS_SUCCESS = "SEARCH_FRIENDS_SUCCESS";
export const SEARCH_FRIENDS_FAIL = "SEARCH_FRIENDS_FAIL";

export const ADD_TO_FRIENDS = "ADD_TO_FRIENDS";

export const FRIENDS_REQUESTS = "FRIENDS_REQUESTS";
export const FRIENDS_REQUESTS_SUCCESS = "FRIENDS_REQUESTS_SUCCESS";
export const FRIENDS_REQUESTS_FAIL = "FRIENDS_REQUESTS_FAIL";

export const ACCEPT_FRIEND_REQUEST = "ACCEPT_FRIEND_REQUEST";

export const REMOVE_FRIEND = "REMOVE_FRIEND";

export const CHALLENGE_FRIEND = "CHALLENGE_FRIEND";

export const ADD_ALL_USERS_AS_FRIENDS = "ADD_ALL_USERS_AS_FRIENDS";

//intro actions

export const INTRO = "INTRO";
export const INTRO_SUCCESS = "INTRO_SUCCESS";
export const INTRO_FAIL = "INTRO_FAIL";

export const UPDATE_INTRO = "UPDATE_INTRO";

export const INTRO_PLATFORMS = "INTRO_PLATFORMS";
export const INTRO_PLATFORMS_SUCCESS = "INTRO_PLATFORMS_SUCCESS";
export const INTRO_PLATFORMS_FAIL = "INTRO_PLATFORMS_FAIL";

export const INTRO_PATCH_PLATFORM = "INTRO_PATCH_PLATFORM";

export const UPDATE_INTRO_PPLATFORM = "UPDATE_INTRO_PPLATFORM";

export const PATCH_INTRO = "PATCH_INTRO";
export const PATCH_INTRO_SUCCESS = "PATCH_INTRO_SUCCESS";

//game center actions

export const USERS_ONLINE = "USERS_ONLINE";
export const USERS_ONLINE_SUCCESS = "USERS_ONLINE_SUCCESS";
export const USERS_ONLINE_FAIL = "USERS_ONLINE_FAIL";

export const CENTER_INFO = "CENTER_INFO";
export const CENTER_INFO_SUCCESS = "CENTER_INFO_SUCCESS";
export const CENTER_INFO_FAIL = "CENTER_INFO_FAIL";

export const START_CREATE_GAME = "START_CREATE_GAME";

export const SET_GAME_DATA = "SET_GAME_DATA";

export const POST_GAME_MODE = "POST_GAME_MODE";
export const POST_GAME_MODE_SUCCESS = "POST_GAME_MODE_SUCCESS";

export const UPLOAD_RESULT = "UPLOAD_RESULT";

export const ONE_V_ONE_SUBMIT_LOSS = "ONE_V_ONE_SUBMIT_LOSS";

export const DELETE_SELECT_FRIEND = "DELETE_SELECT_FRIEND";

export const DELETE_INVITE_FRIEND = "DELETE_INVITE_FRIEND";

export const SELECT_FRIEND_FOR_GAME = "SELECT_FRIEND_FOR_GAME";

export const INVITE_FRIEND_FOR_GAME = "INVITE_FRIEND_FOR_GAME";

export const GAME_FRIEND_REQUEST = "GAME_FRIEND_REQUEST";
export const GAME_FRIEND_REQUEST_SUCCESS = "GAME_FRIEND_REQUEST_SUCCESS";

export const GAME_FRIEND_REQUEST_ACCEPT = "GAME_FRIEND_REQUEST_ACCEPT";

export const GAME_FRIEND_REQUEST_CANCEL = "GAME_FRIEND_REQUEST_CANCEL";

export const GAME_REMATCH_REQUEST = "GAME_REMATCH_REQUEST";
export const GAME_REMATCH_REQUEST_SUCCESS = "GAME_REMATCH_REQUEST_SUCCESS";

export const GAME_REMATCH_REQUEST_ACCEPT = "GAME_REMATCH_REQUEST_ACCEPT";

export const GAME_REMATCH_REQUEST_CANCEL = "GAME_REMATCH_REQUEST_CANCEL";

export const START_FRIEND_GAME = "START_FRIEND_GAME";

export const POST_SEARCH_REQUEST_CONNECT = "POST_SEARCH_REQUEST_CONNECT";

export const GET_ONLINE_USERS = "GET_ONLINE_USERS";
export const GET_ONLINE_USERS_SUCCESS = "GET_ONLINE_USERS_SUCCESS";

export const GET_OPEN_CHALLENGES = "GET_OPEN_CHALLENGES";
export const GET_OPEN_CHALLENGES_SUCCESS = "GET_OPEN_CHALLENGES_SUCCESS";

//notifications actions

export const SET_NOTIFICATIONS_SECTIONS = "SET_NOTIFICATIONS_SECTIONS";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const UPDATE_NOTIFICATIONS_SECTIONS = "UPDATE_NOTIFICATIONS_SECTIONS";
export const UPDATE_NOTIFICATIONS_SECTIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SECTIONS_SUCCESS";
export const UPDATE_NOTIFICATIONS_SECTIONS_FAIL = "UPDATE_NOTIFICATIONS_SECTIONS_FAIL";

//chat actions

export const GET_CHATS_LIST = "GET_CHATS_LIST";
export const GET_CHATS_LIST_SUCCESS = "GET_CHATS_LIST_SUCCESS";
export const GET_CHATS_LIST_FAIL = "GET_CHATS_LIST_FAIL";

export const GET_GROUP_CHATS_LIST = "GET_GROUP_CHATS_LIST";
export const GET_GROUP_CHATS_LIST_SUCCESS = "GET_GROUP_CHATS_LIST_SUCCESS";
export const GET_GROUP_CHATS_LIST_FAIL = "GET_GROUP_CHATS_LIST_FAIL";

export const SELECT_CHAT = "SELECT_CHAT";

export const SELECT_GROUP_CHAT = "SELECT_GROUP_CHAT";

export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_FAIL = "GET_CHAT_MESSAGES_FAIL";

export const GET_GROUP_CHAT_MESSAGES = "GET_GROUP_CHAT_MESSAGES";
export const GET_GROUP_CHAT_MESSAGES_SUCCESS = "GET_GROUP_CHAT_MESSAGES_SUCCESS";

export const UPDATE_MESSAGE_LIST = "UPDATE_MESSAGE_LIST";

export const UPDATE_GROUP_CHAT = "UPDATE_GROUP_CHAT";

export const POST_CHAT_IMAGE = "POST_CHAT_IMAGE";
export const POST_CHAT_IMAGE_SUCCESS = "POST_CHAT_IMAGE_SUCCESS";
export const POST_CHAT_IMAGE_FAIL = "POST_CHAT_IMAGE_FAIL";

export const UPDATE_CHATS_READ = "UPDATE_CHATS_READ";

export const RESET_CHAT_REDUCER = "RESET_CHAT_REDUCER";

export const GET_CHAT_USERS_STATUS = "GET_CHAT_USERS_STATUS";
export const GET_CHAT_USERS_STATUS_SUCCESS = "GET_CHAT_USERS_STATUS_SUCCESS";
export const GET_CHAT_USERS_STATUS_FAIL = "GET_CHAT_USERS_STATUS_FAIL";

//dispute action

export const DISPUTES_LIST = "DISPUTES_LIST";
export const DISPUTES_LIST_SUCCESS = "DISPUTES_LIST_SUCCESS";
export const DISPUTES_LIST_FAIL = "DISPUTES_LIST_FAIL";

export const DISPUTE_DETAIL = "DISPUTE_DETAIL";
export const DISPUTE_DETAIL_SUCCESS = "DISPUTE_DETAIL_SUCCESS";
export const DISPUTE_DETAIL_FAIL = "DISPUTE_DETAIL_FAIL";

export const DISPUTE_MESSAGES = "DISPUTE_MESSAGES";
export const DISPUTE_MESSAGES_SUCCESS = "DISPUTE_MESSAGES_SUCCESS";
export const DISPUTE_MESSAGES_FAIL = "DISPUTE_MESSAGES_FAIL";

export const UPDATE_DISPUTE_MESSAGE_LIST = "UPDATE_DISPUTE_MESSAGE_LIST";

export const UPDATE_DISPUTE_RESULT = "UPDATE_DISPUTE_RESULT";

//info actions

export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAIL = "GET_FAQ_FAIL";

export const GET_INFO = "GET_INFO";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAIL = "GET_INFO_FAIL";

export const SUPPORT = "SUPPORT";
export const SUPPORT_SUCCESS = "SUPPORT_SUCCESS";
export const SUPPORT_FAIL = "SUPPORT_FAIL";

export const GAMES_LIST = "GAMES_LIST";
export const GAMES_LIST_SUCCESS = "GAMES_LIST_SUCCESS";
export const GAMES_LIST_FAIL = "GAMES_LIST_FAIL";

export const GAME_RULES = "GAME_RULES";
export const GAME_RULES_SUCCESS = "GAME_RULES_SUCCESS";
export const GAME_RULES_FAIL = "GAME_RULES_FAIL";

//wallet actions

export const USE_PROMOCODE = "USE_PROMOCODE";
export const USE_PROMOCODE_SUCCESS = "USE_PROMOCODE_SUCCESS";
export const USE_PROMOCODE_FAIL = "USE_PROMOCODE_FAIL";

export const POST_CHANGE_PROMOCODE = "POST_CHANGE_PROMOCODE";
export const POST_CHANGE_PROMOCODE_SUCCESS = "POST_CHANGE_PROMOCODE_SUCCESS";

export const POST_REFERRAL_CLICK = "POST_REFERRAL_CLICK";

export const TRANSACTIONS = "TRANSACTIONS";
export const TRANSACTIONS_SUCCESS = "TRANSACTIONS_SUCCESS";
export const TRANSACTIONS_FAIL = "TRANSACTIONS_FAIL";

export const WALLET_GAMES = "WALLET_GAMES";
export const WALLET_GAMES_SUCCESS = "WALLET_GAMES_SUCCESS";
export const WALLET_GAMES_FAIL = "WALLET_GAMES_FAIL";

export const BOY_GAMES = "BOY_GAMES";
export const BOY_GAMES_SUCCESS = "BOY_GAMES_SUCCESS";
export const BOY_GAMES_FAIL = "BOY_GAMES_FAIL";

export const GET_AFFILIATE_TRANSACTIONS = "GET_AFFILIATE_TRANSACTIONS";
export const GET_AFFILIATE_TRANSACTIONS_SUCCESS = "GET_AFFILIATE_TRANSACTIONS_SUCCESS";
export const GET_AFFILIATE_TRANSACTIONS_FAIL = "GET_AFFILIATE_TRANSACTIONS_FAIL";

export const SET_WALLET_DATA = "CLEAR_WALLET_DATA";

export const CREATE_DEPOSIT = "CREATE_DEPOSIT";
export const CREATE_DEPOSIT_SUCCESS = "CREATE_DEPOSIT_SUCCESS";
export const CREATE_DEPOSIT_FAIL = "CREATE_DEPOSIT_FAIL";

export const POST_PAYSAFECARD_DEPOSIT = "POST_PAYSAFECARD_DEPOSIT";

export const EXECUTE_DEPOSIT = "EXECUTE_DEPOSIT";
export const EXECUTE_DEPOSIT_SUCCESS = "EXECUTE_DEPOSIT_SUCCESS";
export const EXECUTE_DEPOSIT_FAIL = "EXECUTE_DEPOSIT_FAIL";

export const CREATE_WITHDRAW = "CREATE_WITHDRAW";
export const CREATE_WITHDRAW_SUCCESS = "CREATE_WITHDRAW_SUCCESS";
export const CREATE_WITHDRAW_FAIL = "CREATE_WITHDRAW_FAIL";

export const POST_SWIPELUX_PAYMENT = "POST_SWIPELUX_PAYMENT";

export const GET_SWIPELUX_FEE = "GET_SWIPELUX_FEE";

//platformConnect actions

export const GET_PLATFORMS = "GET_PLATFORMS";
export const GET_PLATFORMS_SUCCESS = "GET_PLATFORMS_SUCCESS";
export const GET_PLATFORMS_FAIL = "GET_PLATFORMS_FAIL";

export const POST_PLATFORM = "POST_PLATFORM";
export const POST_PLATFORM_SUCCESS = "POST_PLATFORM_SUCCESS";
export const POST_PLATFORM_FAIL = "POST_PLATFORM_FAIL";

export const VERIFY_PLATFORM = "VERIFY_PLATFORM";
export const VERIFY_PLATFORM_SUCCESS = "VERIFY_PLATFORM_SUCCESS";
export const VERIFY_PLATFORM_FAIL = "VERIFY_PLATFORM_FAIL";

export const DISCONNECT_PLATFORM = "DISCONNECT_PLATFORM";

export const VERIFY_PLATFORM_BY_PROFILE_NAME = "VERIFY_PLATFORM_BY_PROFILE_NAME";

export const CONNECT_BY_FRIEND_REQUEST = "CONNECT_BY_FRIEND_REQUEST";
export const VERIFY_SUPERCELL_LINK = "VERIFY_SUPERCELL_LINK";
export const APPROVE_SUPERCELL_LINK = "APPROVE_SUPERCELL_LINK";

export const CONNECT_LATER = "CONNECT_LATER";

export const POST_GAME_CONNECT_ERROR_FEEDBACK = "POST_GAME_CONNECT_ERROR_FEEDBACK";

//BOY actions

export const GET_BOY_GAMES = "GET_BOY_GAMES";
export const GET_BOY_GAMES_SUCCESS = "GET_BOY_GAMES_SUCCESS";

export const GET_CURRENT_GAME = "GET_CURRENT_GAME";
export const GET_CURRENT_GAME_SUCCESS = "GET_CURRENT_GAME_SUCCESS";

export const GET_ODDS = "GET_ODDS";
export const GET_ODDS_SUCCESS = "GET_ODDS_SUCCESS";

export const POST_MATCH = "POST_MATCH";

export const DELETE_GAME = "DELETE_GAME";

export const CONNECT_USERNAME = "CONNECT_USERNAME";
export const CONNECT_USERNAME_FAIL = "CONNECT_USERNAME_FAIL";

export const GET_MATCH_INFO = "GET_MATCH_INFO";
export const GET_MATCH_INFO_SUCCESS = "GET_MATCH_INFO_SUCCESS";

export const POST_SUBMIT_LOSS = "POST_SUBMIT_LOSS";
export const POST_SUBMIT_RESULT = "POST_SUBMIT_RESULT";

export const GET_BOY_TEXTS = "GET_BOY_TEXTS";
export const GET_BOY_TEXTS_SUCCESS = "GET_BOY_TEXTS_SUCCESS";

export const POST_BOY_MATCH_FEEDBACK = "POST_BOY_MATCH_FEEDBACK";

//BLOGGER actions

export const GET_BLOGGER_USERS = "GET_BLOGGER_USERS";
export const GET_BLOGGER_USERS_SUCCESS = "GET_BLOGGER_USERS_SUCCESS";

export const GET_BLOGGER_TOTALS = "GET_BLOGGER_TOTALS";
export const GET_BLOGGER_TOTALS_SUCCESS = "GET_BLOGGER_TOTALS_SUCCESS";

export const GET_BLOGGER_SKILL_CHALLENGES_USERS = "GET_BLOGGER_SKILL_CHALLENGES_USERS";
export const GET_BLOGGER_SKILL_CHALLENGES_USERS_SUCCESS =
  "GET_BLOGGER_SKILL_CHALLENGES_USERS_SUCCESS";

export const GET_BLOGGER_CSV = "GET_BLOGGER_CSV";

export const SET_SOCKET_DATA = "SET_SOCKET_DATA";
export const SET_SOCKET_NEW_BRACKET = "SET_SOCKET_NEW_BRACKET";
export const SET_SOCKET_MATCH_UPDATED = "SET_SOCKET_MATCH_UPDATED";
export const SET_SOCKET_MATCH_MESSAGE = "SET_SOCKET_MATCH_MESSAGE";

export const SET_EXPIRATION_DATA = "SET_EXPIRATION_DATA";

export const SET_MATCH_STARTING_BEFORE_TOURNAMENT_DIALOG =
  "SET_MATCH_STARTING_BEFORE_TOURNAMENT_DIALOG";
export const CLOSE_MATCH_STARTING_BEFORE_TOURNAMENT_DIALOG =
  "CLOSE_MATCH_STARTING_BEFORE_TOURNAMENT_DIALOG";

export const HANLDE_FREE_BALANCE_DIALOG = "HANLDE_FREE_BALANCE_DIALOG";
export const HANLDE_VERIFICATION_REQUIRED_DIALOG = "HANLDE_VERIFICATION_REQUIRED_DIALOG";

//PUBLIC actions

export const POST_AFFILIATE_LANDING_PAGE = "POST_AFFILIATE_LANDING_PAGE";

//TOURNAMENTS actions

export const BUTTON_LOADING = "BUTTON_LOADING";

export const HANDLE_OK_DIALOG = "HANDLE_OK_DIALOG";
export const HANDLE_CHANGE_PHONE_NUMBER_DIALOG = "HANDLE_CHANGE_PHONE_NUMBER_DIALOG";
export const HANDLE_ENTER_SMS_CODE_DIALOG = "HANDLE_ENTER_SMS_CODE_DIALOG";

export const GET_TOURNAMENTS_GAMES = "GET_TOURNAMENTS_GAMES";
export const GET_TOURNAMENTS_GAMES_SUCCESS = "GET_TOURNAMENTS_GAMES_SUCCESS";

export const RESET_TOURNAMENTS = "RESET_TOURNAMENTS";
export const SET_USERS_LIST = "SET_USERS_LIST";

export const GET_TOURNAMENTS_GAME_INFO = "GET_TOURNAMENTS_GAME_INFO";
export const GET_TOURNAMENTS_GAME_INFO_SUCCESS = "GET_TOURNAMENTS_GAME_INFO_SUCCESS";

export const GET_TOURNAMENTS = "GET_TOURNAMENTS";
export const GET_TOURNAMENTS_SUCCESS = "GET_TOURNAMENTS_SUCCESS";

export const GET_PARTICIPANTS_LIST = "GET_PARTICIPANTS_LIST";
export const GET_JOINED_LIST = "GET_JOINED_LIST";
export const GET_WAITLIST_LIST = "GET_WAITLIST_LIST";

export const POST_ADD_TO_WAITLIST = "POST_ADD_TO_WAITLIST";
export const POST_ADD_TO_WAITLIST_FAIL = "POST_ADD_TO_WAITLIST_FAIL";

export const POST_JOIN_TOURNAMENT = "POST_JOIN_TOURNAMENT";
export const POST_JOIN_TOURNAMENT_FAIL = "POST_JOIN_TOURNAMENT_FAIL";

export const GET_TOURNAMENT_GAME_RULES = "GET_TOURNAMENT_GAME_RULES";

export const SET_CONFIRMATION_DIALOG = "SET_CONFIRMATION_DIALOG";
export const CLOSE_CONFIRMATION_DIALOG = "CLOSE_CONFIRMATION_DIALOG";

export const HANDLE_FIRST_PLACE_REWARD_DIALOG = "HANDLE_FIRST_PLACE_REWARD_DIALOG";

export const GET_TOURNAMENT_BRACKET = "GET_TOURNAMENT_BRACKET";
export const GET_TOURNAMENT_BRACKET_SUCCESS = "GET_TOURNAMENT_BRACKET_SUCCESS";

export const POST_TOURNAMENT_CHECK_IN = "POST_TOURNAMENT_CHECK_IN";
export const POST_TOURNAMENT_CHECK_IN_SUCCESS = "POST_TOURNAMENT_CHECK_IN_SUCCESS";
export const POST_TOURNAMENT_CHECK_IN_FAIL = "POST_TOURNAMENT_CHECK_IN_FAIL";

export const GET_TOURNAMENT_CHECK_IN_INFO = "GET_TOURNAMENT_CHECK_IN_INFO";

export const GET_TOURNAMENT_DETAIL = "GET_TOURNAMENT_DETAIL";
export const GET_TOURNAMENT_DETAIL_SUCCESS = "GET_TOURNAMENT_DETAIL_SUCCESS";
export const GET_TOURNAMENT_DETAIL_FAIL = "GET_TOURNAMENT_DETAIL_FAIL";

export const GET_TOURNAMENT_CURRENT_MATCH = "GET_TOURNAMENT_CURRENT_MATCH";
export const GET_TOURNAMENT_CURRENT_MATCH_SUCCESS = "GET_TOURNAMENT_CURRENT_MATCH_SUCCESS";

export const POST_TOURNAMENT_APPROVING_OPPONENT = "POST_TOURNAMENT_APPROVING_OPPONENT";
export const POST_TOURNAMENT_APPROVING_OPPONENT_SUCCESS =
  "POST_TOURNAMENT_APPROVING_OPPONENT_SUCCESS";

export const POST_TOURNAMENT_QUIT = "POST_TOURNAMENT_QUIT";
export const POST_TOURNAMENT_QUIT_SUCCESS = "POST_TOURNAMENT_QUIT_SUCCESS";

export const POST_TOURNAMENT_SUBMIT_RESULTS = "POST_TOURNAMENT_SUBMIT_RESULTS";
export const POST_TOURNAMENT_SUBMIT_RESULTS_SUCCESS = "POST_TOURNAMENT_SUBMIT_RESULTS_SUCCESS";

export const POST_TOURNAMENT_SUBMIT_LOSS = "POST_TOURNAMENT_SUBMIT_LOSS";
export const POST_TOURNAMENT_SUBMIT_LOSS_SUCCESS = "POST_TOURNAMENT_SUBMIT_LOSS_SUCCESS";

export const POST_TOURNAMENT_SUBMIT_WON = "POST_TOURNAMENT_SUBMIT_WON";
export const POST_TOURNAMENT_SUBMIT_WON_SUCCESS = "POST_TOURNAMENT_SUBMIT_WON_SUCCESS";

export const POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT =
  "POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT";
export const POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT_SUCCESS =
  "POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT_SUCCESS";
export const POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT_FAIL =
  "POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT_FAIL";

export const GET_GAME_DETAILS = "GET_GAME_DETAILS";
export const GET_GAME_DETAILS_SUCCESS = "GET_GAME_DETAILS_SUCCESS";

export const RESET_CURRENT_MATCH = "RESET_CURRENT_MATCH";

// challenges actions

export const GET_DAILY_CHAMPION_CHALLENGES = "GET_DAILY_CHAMPION_CHALLENGES";
export const GET_DAILY_CHAMPION_CHALLENGES_SUCCESS = "GET_DAILY_CHAMPION_CHALLENGES_SUCCESS";

export const GET_RANK_CHALLENGES = "GET_RANK_CHALLENGES";
export const GET_RANK_CHALLENGES_SUCCESS = "GET_RANK_CHALLENGES_SUCCESS";

export const CLAIM_RANK_CHALLENGES_REWARD = "CLAIM_RANK_CHALLENGES_REWARD";
export const CLAIM_RANK_CHALLENGES_REWARD_SUCCESS = "CLAIM_RANK_CHALLENGES_REWARD_SUCCESS";
export const CLAIM_RANK_CHALLENGES_REWARD_FAIL = "CLAIM_RANK_CHALLENGES_REWARD_FAIL";

export const GET_MY_CHALLENGES_INFO = "GET_MY_CHALLENGES_INFO";
export const GET_MY_CHALLENGES_INFO_SUCCESS = "GET_MY_CHALLENGES_INFO_SUCCESS";

// challenges actions

export const GET_REWARDS_INFO = "GET_REWARDS_INFO";
export const GET_REWARDS_INFO_SUCCESS = "GET_REWARDS_INFO_SUCCESS";

export const CLAIM_LOGIN_REWARD = "CLAIM_LOGIN_REWARD";
export const CLAIM_LOGIN_REWARD_SUCCESS = "CLAIM_LOGIN_REWARD_SUCCESS";

export const BUY_PSN_VOUCHER = "BUY_PSN_VOUCHER";
export const BUY_PSN_VOUCHER_SUCCESS = "BUY_PSN_VOUCHER_SUCCESS";

// vip club actions

export const GET_VIP_CLUB = "GET_VIP_CLUB";
export const GET_VIP_CLUB_SUCCESS = "GET_VIP_CLUB_SUCCESS";
