import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import UserReducer from "./reducerUser";
import ProfileReducer from "./reducerProfile";
import LeaderboardReducer from "./reducerLeaderboard";
import FriendsReducer from "./reducerFriends";
import IntroReducer from "./reducerIntro";
import GameCenterReducer from "./reducerGameCenter";
import NotificationsReducer from "./reducerNotifications";
import ChatReducer from "./reducerChat";
import DisputeReducer from "./reducerDispute";
import ChallengesReducer from "./reducerChallenges";
import VipClubReducer from "./reducerVipClub";
import InfoReducer from "./reducerInfo";
import WalletReducer from "./reducerWallet";
import PlatformConnectReducer from "./reducerPlatformConnect";
import BOYReducer from "./reducerBOY";
import BloggerReducer from "./reducerBlogger";
import SocketReducer from "./reducerSocket";
import TournamentsReducer from "./reducerTournaments";
import RewardsReducer from "./reducerRewards";
import ICOReducer from "../containers/ICOWebsite/reducer";
import B2BReducer from "../containers/B2BWebsite/reducer";
import { reducer as formReducer } from "redux-form";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    user: UserReducer,
    profile: ProfileReducer,
    leaderboard: LeaderboardReducer,
    friends: FriendsReducer,
    intro: IntroReducer,
    game_center: GameCenterReducer,
    notifications: NotificationsReducer,
    chat: ChatReducer,
    dispute: DisputeReducer,
    challenges: ChallengesReducer,
    vipClub: VipClubReducer,
    info: InfoReducer,
    wallet: WalletReducer,
    platforms: PlatformConnectReducer,
    boy: BOYReducer,
    blogger: BloggerReducer,
    socket: SocketReducer,
    tournaments: TournamentsReducer,
    rewards: RewardsReducer,
    ico: ICOReducer,
    b2b: B2BReducer
  });

export default rootReducer;
