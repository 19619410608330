import React, { Fragment } from "react";
import { toast } from "react-toastify";

import "../style/main.scss";

toast.configure();

const App = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export default App;
