import * as types from "../actions/constants";

const INITIAL_STATE = {
  transactions: { results: [] },
  games: { results: [] },
  boy_games: { results: [] },
  affiliateTransactions: { results: [] },
  balance: null,
  bonus_cash_balance: 0,
  crypto_balance: 0,
  promocode_invitation: null,
  payment_cancel: null,
  payment_success: null,
  error: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TRANSACTIONS_SUCCESS:
      let transactionObj = {
        ...state.transactions,
        ...action.payload.data,
        results: [...state.transactions.results, ...action.payload.data.results]
      };
      return {
        ...state,
        transactions: transactionObj,
        balance: action.payload.data.balance,
        promocode_invitation: action.payload.data.can_apply_invitation
      };
    case types.WALLET_GAMES_SUCCESS:
      let gamesObj = {
        ...state.games,
        ...action.payload.data,
        results: [...state.games.results, ...action.payload.data.results]
      };
      return {
        ...state,
        games: gamesObj,
        balance: action.payload.data.balance,
        promocode_invitation: action.payload.data.can_apply_invitation
      };
    case types.BOY_GAMES_SUCCESS:
      let boyGamesObj = {
        ...state.boy_games,
        ...action.payload.data,
        results: [...state.boy_games.results, ...action.payload.data.results]
      };
      return {
        ...state,
        boy_games: boyGamesObj,
        balance: action.payload.data.balance,
        promocode_invitation: action.payload.data.can_apply_invitation
      };
    case types.GET_AFFILIATE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        affiliateTransactions: {
          ...state.affiliateTransactions,
          ...action.payload.data,
          results: [...state.affiliateTransactions.results, ...action.payload.data.results]
        },
        balance: action.payload.data.balance,
        promocode_invitation: action.payload.data.can_apply_invitation
      };
    case types.SET_WALLET_DATA:
      return { ...state, [action.field]: action.data };

    case types.TRANSACTIONS_FAIL:
      return { ...state, error: action.error.response.data };
    case types.WALLET_GAMES_FAIL:
      return { ...state, error: action.error.response.data };
    case types.CREATE_WITHDRAW_FAIL:
      return { ...state, error: action.error.response.data };
    default:
      return state;
  }
}
