import * as types from "../actions/constants";

const INITIAL_STATE = {
  games: [],
  current_game: {},
  matchInfo: {},
  odds: {
    odds_output: {
      1: 1,
      2: 2,
      3: 3
    }
  },
  results: {},
  error: "",
  texts: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_BOY_GAMES_SUCCESS:
      return { ...state, games: action.payload.data };
    case types.GET_CURRENT_GAME_SUCCESS:
      return { ...state, current_game: action.payload.data };
    case types.GET_ODDS_SUCCESS:
      return { ...state, odds: action.payload.data };
    case types.GET_MATCH_INFO_SUCCESS:
      return { ...state, matchInfo: action.payload.data };
    case types.GET_BOY_TEXTS_SUCCESS:
      return { ...state, texts: action.payload.data };
    case types.CONNECT_USERNAME_FAIL:
      return { ...state, error: action.error.response.data.username };
    default:
      return state;
  }
}
