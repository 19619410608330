import * as types from "../actions/constants";

const INITIAL_STATE = {
  users_online: 0,
  open_challenges: 0,
  friend_game: undefined,
  rematch_invite: undefined,
  game_mode_reset: false,
  tournament_waitlist_started: null,
  tournament_checkin_started: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SOCKET_DATA:
      return {
        ...state,
        [action.field]:
          action.field === "friend_game" ||
          action.field === "rematch_invite" ||
          action.field === "tournament_waitlist_started" ||
          action.field === "tournament_checkin_started"
            ? action.data
            : action.field === "game_mode_reset"
            ? true
            : action.data[action.field]
      };
    default:
      return state;
  }
}
