import React from "react";
import { withTranslation, Trans } from "react-i18next";

import Logo from "../../../assets/image/loader_logo.svg";

import "./Loader.scss";

const Loader = (props) => {
  return (
    <div className="loader_wrapper">
      <div className="loading">
        <Trans i18nKey="common:common-blocks.loading">Loading</Trans>
      </div>
      <div className="loader_container">
        <img src={Logo} alt="Gaming Stars" />
      </div>
    </div>
  );
};

export default withTranslation()(Loader);
