import * as types from "./actionTypes";

const INITIAL_STATE = {
  submitting: false,
  isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i),
  offset: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.POST_BOOK_A_DEMO:
      return { ...state, submitting: true };
    case types.POST_BOOK_A_DEMO_FAIL:
    case types.POST_BOOK_A_DEMO_SUCCESS:
      return { ...state, submitting: false };

    case types.SET_B2B_OFFSET:
      return { ...state, offset: action.offset };
    default:
      return state;
  }
}
