import * as types from "../actions/constants";

const INITIAL_STATE = {
  totals: { balance: 0, total_revenue_sum: 0, results: [] },
  users: [],
  skillChallengesUsers: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_BLOGGER_TOTALS_SUCCESS:
      return { ...state, totals: action.payload.data };
    case types.GET_BLOGGER_USERS_SUCCESS:
      return { ...state, users: action.payload.data.results };
    case types.GET_BLOGGER_SKILL_CHALLENGES_USERS_SUCCESS:
      return { ...state, skillChallengesUsers: action.payload.data.results };
    default:
      return state;
  }
}
