import * as types from "../actions/constants";

const INITIAL_STATE = {
  dailyChampionChallenges: {
    initial: true
  },
  rankChallenges: {
    initial: true
  },
  myInfo: {
    initial: true
  },
  resetMyInfo: true,
  fetchingChallenges: true
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_DAILY_CHAMPION_CHALLENGES_SUCCESS:
      return { ...state, dailyChampionChallenges: action.payload.data };
    case types.GET_RANK_CHALLENGES:
      return { ...state, fetchingChallenges: true };
    case types.GET_RANK_CHALLENGES_SUCCESS:
      return { ...state, rankChallenges: action.payload.data, fetchingChallenges: false };
    case types.GET_MY_CHALLENGES_INFO_SUCCESS:
      return {
        ...state,
        myInfo: action.payload.data,
        resetMyInfo: false
      };
    case types.CLAIM_RANK_CHALLENGES_REWARD_SUCCESS:
      return { ...state, resetMyInfo: true };
    default:
      return state;
  }
}
