import * as types from "../actions/constants";

const INITIAL_STATE = {
  user_info: {},
  globalSettings: {},
  preferences: {},
  error: [],
  reportId: null,
  openGetFreeBalanceDialog: false,
  openVerificationRequiredDialog: false,
  seconds_left: 0,
  matchStartingBeforeTournamentDialog: {
    open: false,
    continueAction: () => {},
    description: ""
  },
  userCurrency: undefined
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.MY_PROFILE_SUCCESS:
      return {
        ...state,
        user_info: { ...state.user_info, ...action.payload.data },
        userCurrency: action.payload.data.currency
      };
    case types.GET_GLOBAL_SETTINGS_SUCCESS:
      return { ...state, globalSettings: action.payload.data };
    case types.EDIT_MY_PROFILE_SUCCESS:
      return { ...state, user_info: { ...state.user_info, ...action.payload.data } };
    case types.POST_CHANGE_PROMOCODE_SUCCESS:
      return {
        ...state,
        user_info: {
          ...state.user_info,
          ...action.payload.data
        }
      };
    case types.PREFERENCES_SUCCESS:
      return { ...state, preferences: action.payload.data };
    case types.SET_PREFERENCES_SUCCESS:
      return { ...state, preferences: action.payload.data };
    case types.MY_PROFILE_FAIL:
      return { ...state, error: action.error.response.data };
    case types.EDIT_MY_PROFILE_FAIL:
      return { ...state, error: action.error.response.data };
    case types.PREFERENCES_FAIL:
      return { ...state, error: action.error.response.data };
    case types.SET_PREFERENCES_FAIL:
      return { ...state, error: action.error.response.data };

    case types.SET_EXPIRATION_DATA:
      return { ...state, seconds_left: action.seconds_left };
    case types.HANLDE_FREE_BALANCE_DIALOG:
      return { ...state, openGetFreeBalanceDialog: action.payload };
    case types.HANLDE_VERIFICATION_REQUIRED_DIALOG:
      return { ...state, openVerificationRequiredDialog: action.payload };
    case types.PUT_ID_FOR_REPORT:
      return { ...state, reportId: action.id };
    case types.RESET_ID_FOR_REPORT:
      return { ...state, reportId: null };
    case types.SET_MATCH_STARTING_BEFORE_TOURNAMENT_DIALOG:
      return {
        ...state,
        matchStartingBeforeTournamentDialog: action.matchStartingBeforeTournamentDialog
      };
    case types.CLOSE_MATCH_STARTING_BEFORE_TOURNAMENT_DIALOG:
      return {
        ...state,
        matchStartingBeforeTournamentDialog: {
          ...state.matchStartingBeforeTournamentDialog,
          open: false
        }
      };

    default:
      return state;
  }
}
