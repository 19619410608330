import * as types from "../actions/constants";

const INITIAL_STATE = {
  rewardsInfo: { loading: true },
  claimingReward: false,
  buyingVoucher: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_REWARDS_INFO_SUCCESS:
      return { ...state, rewardsInfo: action.payload.data };
    case types.CLAIM_LOGIN_REWARD:
      return {
        ...state,
        claimingReward: true
      };
    case types.CLAIM_LOGIN_REWARD_SUCCESS:
      return {
        ...state,
        rewardsInfo: {
          ...state.rewardsInfo,
          is_claimed_today: true,
          weekly_login_streak: state.rewardsInfo.weekly_login_streak + 1
        },
        claimingReward: false
      };
    case types.BUY_PSN_VOUCHER:
      return {
        ...state,
        buyingVoucher: true
      };
    case types.BUY_PSN_VOUCHER_SUCCESS:
      return {
        ...state,
        buyingVoucher: false
      };
    default:
      return state;
  }
}
