import * as types from "./actionTypes";

const INITIAL_STATE = {
  joined: 0,
  submitting: false,
  signedIn: false,
  isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i),
  offset: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_ICO_PEOPLE_JOINED_SUCCESS:
      return { ...state, joined: action.payload.data.count };
    case types.POST_ICO_JOIN_WHITELIST:
      return { ...state, submitting: true, signedIn: false };
    case types.POST_ICO_JOIN_WHITELIST_FAIL:
    case types.POST_ICO_JOIN_WHITELIST_SUCCESS:
      return { ...state, submitting: false, signedIn: true };

    case types.SET_ICO_OFFSET:
      return { ...state, offset: action.offset };
    default:
      return state;
  }
}
