import * as types from "../actions/constants";

const INITIAL_STATE = {
  list: [],
  code: [],
  errors: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_PLATFORMS_SUCCESS:
      let tmp = action.payload.data[1];
      action.payload.data[1] = action.payload.data[0];
      action.payload.data[0] = tmp;
      return { ...state, list: action.payload.data };
    case types.VERIFY_PLATFORM_SUCCESS:
      return { ...state, verification: action.payload.data };
    // case types.UPDATE_INTRO_PPLATFORM :
    //     let updateIntroPlatform = [...state.platforms];
    //     updateIntroPlatform = updateIntroPlatform.map(item => {
    //         if(item.id === action.id) return {...item, ...action.data};
    //         else return item;
    //     });
    //     return {...state, platforms : updateIntroPlatform};

    case types.POST_PLATFORM_FAIL:
      return { ...state, errors: action.error.response.data };
    case types.VERIFY_PLATFORM_FAIL:
      return { ...state, errors: action.error.response.data };
    default:
      return state;
  }
}
