import * as types from "../actions/constants";

const INITIAL_STATE = {
  games: [],
  gameInfo: { game: { loading: true } },
  tournamentsList: { loading: true, results: [] },
  tournament: { loading: true },
  usersList: { open: false, title: "", numberOfUsers: 0, users: [] },
  confirmationDialog: {
    open: false,
    onClose: () => {},
    confirmAction: () => {},
    declineAction: () => {},
    title: "",
    description: "",
    confirmText: "",
    declineText: ""
  },
  firstPlaceRewardDialog: {
    open: false,
    minParticipants: 0,
    maxParticipants: 0,
    firstPlacePrizeMin: 0,
    firstPlacePrizeMax: 0
  },
  buttonLoading: false,
  bracket: { loading: true, game: {}, platform: {} },
  tournamentDetail: { loading: true },
  okDialog: {
    open: false,
    message: "",
    code: ""
  },
  changePhoneNumberDialog: false,
  enterSMSCodeDialog: false,
  currentMatch: {
    loading: true
  },
  gameDetails: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SOCKET_NEW_BRACKET:
      if (state.tournamentDetail.id === action.bracket.bracket.tournament) {
        return { ...state, bracket: action.bracket };
      }
      return state;
    case types.SET_SOCKET_MATCH_UPDATED:
      return { ...state, currentMatch: action.data };
    case types.SET_SOCKET_MATCH_MESSAGE:
      if (
        action.data.data.type === "kicked" ||
        action.data.data.type === "timeout" ||
        action.data.data.type === "submit_timeout"
      ) {
        return {
          ...state,
          okDialog: {
            open: true,
            message: "",
            code: action.data.data.type
          }
        };
      } else {
        return {
          ...state,
          currentMatch: { ...action.data.data, state: "results" }
        };
      }
    case types.GET_TOURNAMENT_CURRENT_MATCH_SUCCESS:
      return { ...state, currentMatch: action.payload.data };
    case types.BUTTON_LOADING:
      return { ...state, buttonLoading: action.isLoading };
    case types.HANDLE_OK_DIALOG:
      return { ...state, okDialog: action.okDialog };
    case types.HANDLE_CHANGE_PHONE_NUMBER_DIALOG:
      return { ...state, changePhoneNumberDialog: action.changePhoneNumberDialog };
    case types.HANDLE_ENTER_SMS_CODE_DIALOG:
      return { ...state, enterSMSCodeDialog: action.enterSMSCodeDialog };
    case types.GET_TOURNAMENTS_GAMES_SUCCESS:
      return { ...state, games: action.payload.data };
    case types.GET_TOURNAMENTS_GAME_INFO_SUCCESS:
      return { ...state, gameInfo: action.payload.data };
    case types.GET_TOURNAMENTS_SUCCESS:
      return { ...state, tournamentsList: { loading: false, ...action.payload.data } };
    case types.SET_USERS_LIST:
      return { ...state, usersList: { ...state.usersList, ...action.users } };

    case types.RESET_TOURNAMENTS:
      return {
        ...state,
        tournamentsList: INITIAL_STATE.tournamentsList,
        tournament: INITIAL_STATE.tournament,
        usersList: INITIAL_STATE.usersList,
        bracket: INITIAL_STATE.bracket
      };
    case types.SET_CONFIRMATION_DIALOG:
      return { ...state, confirmationDialog: action.confirmationDialog };
    case types.CLOSE_CONFIRMATION_DIALOG:
      return { ...state, confirmationDialog: { ...state.confirmationDialog, open: false } };
    case types.HANDLE_FIRST_PLACE_REWARD_DIALOG:
      return { ...state, firstPlaceRewardDialog: action.firstPlaceRewardDialog };
    case types.GET_TOURNAMENT_BRACKET_SUCCESS:
      return { ...state, bracket: action.payload.data };
    case types.GET_TOURNAMENT_DETAIL:
      return { ...state, tournamentDetail: INITIAL_STATE.tournamentDetail };
    case types.GET_TOURNAMENT_DETAIL_SUCCESS:
      return { ...state, tournamentDetail: action.payload.data };
    case types.GET_TOURNAMENT_DETAIL_FAIL:
      return { ...state, tournamentDetail: { notAvailable: true } };

    case types.POST_JOIN_TOURNAMENT_FAIL:
    case types.POST_ADD_TO_WAITLIST_FAIL:
      return {
        ...state,
        okDialog: {
          open: action.error.response.data.code !== "no_phone_number",
          message: action.error.response.data.message,
          code: action.error.response.data.code
        },
        changePhoneNumberDialog: action.error.response.data.code === "no_phone_number"
      };
    case types.POST_TOURNAMENT_CHECK_IN_SUCCESS:
      return {
        ...state,
        okDialog: {
          open: true,
          message: "",
          code: "success_checked_in"
        }
      };
    case types.POST_TOURNAMENT_CHECK_IN_FAIL:
      return {
        ...state,
        okDialog: {
          open: true,
          message: action.error.response.data.message,
          code: action.error.response.data.code
        }
      };

    case types.POST_TOURNAMENT_APPROVING_OPPONENT:
      return { ...state, buttonLoading: true };
    case types.POST_TOURNAMENT_APPROVING_OPPONENT_SUCCESS:
      return { ...state, buttonLoading: false };
    case types.POST_TOURNAMENT_QUIT:
      return { ...state, buttonLoading: true };
    case types.POST_TOURNAMENT_QUIT_SUCCESS:
      return { ...state, buttonLoading: false };
    case types.POST_TOURNAMENT_SUBMIT_RESULTS:
      return { ...state, buttonLoading: true };
    case types.POST_TOURNAMENT_SUBMIT_RESULTS_SUCCESS:
      return { ...state, buttonLoading: false };
    case types.POST_TOURNAMENT_SUBMIT_LOSS:
      return { ...state, buttonLoading: true };
    case types.POST_TOURNAMENT_SUBMIT_LOSS_SUCCESS:
      return { ...state, buttonLoading: false };
    case types.POST_TOURNAMENT_SUBMIT_WON:
      return { ...state, buttonLoading: true };
    case types.POST_TOURNAMENT_SUBMIT_WON_SUCCESS:
      return { ...state, buttonLoading: false };
    case types.POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT:
      return { ...state, buttonLoading: true };
    case types.POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT_SUCCESS:
      return { ...state, buttonLoading: false };
    case types.POST_TOURNAMENT_DISPUTE_SUBMIT_SCREENSHOT_FAIL:
      return { ...state, buttonLoading: false };
    case types.GET_GAME_DETAILS_SUCCESS:
      return { ...state, gameDetails: action.payload.data };

    case types.RESET_CURRENT_MATCH:
      return { ...state, currentMatch: INITIAL_STATE.currentMatch };
    default:
      return state;
  }
}
