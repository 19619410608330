import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";

import api from "./actions/api";
import routes from "./routes/routes";
import rootReducer from "./reducers";

import Loader from "./components/HelperComponents/Loader/Loader";

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (action, config) => {
        if (localStorage.token || localStorage.token_res) {
          let token = localStorage.token ? localStorage.token : localStorage.token_res;
          config.headers["Authorization"] = "Token " + token;
        }
        if (localStorage.language) {
          config.headers["Accept-Language"] = localStorage.language;
        }
        config.headers["Platform"] = "web";
        return config;
      }
    ],
    response: [
      {
        success: ({ dispatch }, response) => {
          return response;
        },
        error: ({ dispatch }, error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            document.location.reload();
          }
          return Promise.reject(error);
        }
      }
    ]
  }
};

i18next.init({
  interpolation: { escapeValue: false },
  // whitelist: ["en", "de"],
  lng: "en",
  // fallbackLng: "en",
  resources: {
    en: {
      common: common_en
    },
    de: {
      common: common_de
    }
  },
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
  saveMissing: true
});

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const createStoreWithMiddleware = applyMiddleware(
  multiClientMiddleware(api, axiosMiddlewareOptions),
  appRouterMiddleware
)(createStore);
const store = createStoreWithMiddleware(
  rootReducer(history),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={<Loader />}>
        <ConnectedRouter history={history} children={routes} />
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById("wrapper")
);
