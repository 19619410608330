import React from "react";
import { Trans } from "react-i18next";
import clsx from "clsx";

import XboxSmallIcon from "../assets/image/Xbox_small.svg";
import PsnSmallIcon from "../assets/image/Playstation_small.svg";
import SteamSmallIcon from "../assets/image/Steam_small.svg";
import SupercellSmallIcon from "../assets/image/Supercell_small.svg";
import EpicGamesSmallIcon from "../assets/image/Epic_Games_small.svg";
import ActivisionSmallIcon from "../assets/image/Activision_small.svg";

import XboxLogoIcon from "../assets/image/xbox-logo.svg";
import PsnLogoIcon from "../assets/image/psn-logo.svg";
import SteamLogoIcon from "../assets/image/steam-logo.svg";
import SupercellLogoIcon from "../assets/image/supercell-logo.svg";
import EpicGamesLogoIcon from "../assets/image/epic-games-logo.svg";
import ActivisionLogoIcon from "../assets/image/activision-logo.svg";

import CircleIcon from "@material-ui/icons/FiberManualRecord";

import ChallengeGameControl from "../assets/image/challenge_gamepad_x10.png";
import ChallengeFireX5 from "../assets/image/challenge_fire_x5.png";
import ChallengeFireX3 from "../assets/image/challenge_fire_x3.png";
import ChallengeCoinX250 from "../assets/image/challenge_coin_x250.png";
import ChallengeCupX5 from "../assets/image/challenge_cup_x5.png";
import ChallengeMedal from "../assets/image/challenge_medal.png";

import WalletInIcon from "../assets/image/wallet_in.svg";
import WalletOutIcon from "../assets/image/wallet_out.svg";
import PromocodeIcon from "../assets/image/promocode_in.svg";

import WalletGameWinIcon from "../assets/image/wallet_game_win.svg";
import WalletGameLoseIcon from "../assets/image/wallet_game_lose.svg";
import WalletGameDrawIcon from "../assets/image/wallet_game_draw.svg";

export function getConsoleIconSmall(type) {
  switch (type.toLowerCase()) {
    case "xbox":
      return <img src={XboxSmallIcon} key={type} alt="xbox" />;
    case "steam":
      return <img src={SteamSmallIcon} key={type} alt="steam" />;
    case "psn":
    case "playstation":
      return <img src={PsnSmallIcon} key={type} alt="psn" />;
    case "supercell":
    case "clash_royale":
    case "brawl_star":
      return <img src={SupercellSmallIcon} key={type} alt="supercell" />;
    case "epic":
    case "epic games":
      return <img src={EpicGamesSmallIcon} key={type} alt="epic games" />;
    case "activision":
      return <img src={ActivisionSmallIcon} key={type} alt="activision" />;
    default:
      return null;
  }
}

export function getConsoleIcon(type, classes = "") {
  const classNames = clsx("logo_console", classes);
  switch (type.toLowerCase()) {
    case "xbox":
      return <img className={classNames} src={XboxLogoIcon} alt="xbox" />;
    case "steam":
      return <img className={classNames} src={SteamLogoIcon} alt="steam" />;
    case "psn":
    case "playstation":
      return <img className={classNames} src={PsnLogoIcon} alt="psn" />;
    case "supercell":
    case "clash_royale":
    case "brawl_star":
      return <img className={classNames} src={SupercellLogoIcon} alt="supercell" />;
    case "epic":
    case "epic games":
      return <img className={classNames} src={EpicGamesLogoIcon} alt="epic games" />;
    case "activision":
      return <img className={classNames} src={ActivisionLogoIcon} alt="activision" />;
    default:
      return null;
  }
}

export function isSupercell(platformType) {
  return (
    platformType.toLowerCase() === "clash_royale" || platformType.toLowerCase() === "brawl_star"
  );
}

export function isArray(arr) {
  return arr && arr.length && arr.length > 0;
}

export function checkActionForParam(action, param) {
  let params = new URLSearchParams(
    action.meta.previousAction.payload.request.url.split("/")[3].substring(1)
  );
  return params.get(param);
}

export function convertPosition(position) {
  switch (position) {
    case 1:
      return (
        <div>
          1&nbsp;<Trans i18nKey="common:leaderboard.place">place</Trans>
        </div>
      );
    case 2:
      return (
        <div>
          2&nbsp;<Trans i18nKey="common:leaderboard.place">place</Trans>
        </div>
      );
    case 3:
      return (
        <div>
          1&nbsp;<Trans i18nKey="common:leaderboard.place">place</Trans>
        </div>
      );
    default:
      return (
        <div>
          {position}&nbsp;<Trans i18nKey="common:leaderboard.place">place</Trans>
        </div>
      );
  }
}

export function convertMinutes(minutes) {
  let days = Math.floor(minutes / 24 / 60);
  let hours = Math.floor((minutes / 60) % 24);
  let min = Math.floor(minutes % 60);
  return `${days}d ${hours}h ${min}m`;
}

export function tickerSeconds(seconds) {
  if (+seconds === 0) {
    return `00:00`;
  } else {
    let min = Math.floor(+seconds / 60);
    let sec = Math.floor(+seconds % 60);
    if (min < 10) min = `0${Math.max(0, min)}`;
    if (sec < 10) sec = `0${Math.max(0, sec)}`;
    return `${min}:${sec}`;
  }
}

export function getStatus(status) {
  switch (status) {
    case "pending":
      return (
        <p className="dispute_item_status dispute_status_pending">
          <Trans i18nKey="common:common-blocks.pending">Pending</Trans>
          <CircleIcon />
        </p>
      );
    case "win":
      return (
        <p className="dispute_item_status dispute_status_win">
          <Trans i18nKey="common:common-blocks.won">Won</Trans>
          <CircleIcon />
        </p>
      );
    case "loss":
      return (
        <p className="dispute_item_status dispute_status_lost">
          <Trans i18nKey="common:common-blocks.lost">Lost</Trans>
          <CircleIcon />
        </p>
      );
    case "draw":
      return (
        <p className="dispute_item_status dispute_status_draw">
          <Trans i18nKey="common:common-blocks.draw">Draw</Trans>
          <CircleIcon />
        </p>
      );
    default:
      return null;
  }
}

export function getChallengeIcon(challenge) {
  switch (challenge) {
    case "play_10_games_today":
      return <img src={ChallengeGameControl} alt="challenge" />;
    case "win_5_games_in_a_row":
      return <img src={ChallengeFireX5} alt="challenge" />;
    case "win_3_games_in_a_row_with_different_opponent":
      return <img src={ChallengeFireX3} alt="challenge" />;
    case "win_250_euro_in_games":
      return <img src={ChallengeCoinX250} alt="challenge" />;
    case "win_5_games_today":
      return <img src={ChallengeCupX5} alt="challenge" />;
    case "get_the_1st_place_or_higher_in_the_leaderboard":
    case "get_the_3rd_place_or_higher_in_the_leaderboard":
    case "get_the_10th_place_or_higher_in_the_leaderboard":
      return <img src={ChallengeMedal} alt="challenge" />;
    default:
      return null;
  }
}

export function getTransactionIcon(type) {
  switch (type) {
    case "paypal_deposit":
    case "paysafecard":
    case "challenge_skill":
    case "challenge_1vs1":
    case "swipelux_deposit":
    case "":
      return <img src={WalletInIcon} alt="wallet" />;
    case "paypal_withdraw":
    case "bank_withdrawal":
      return <img src={WalletOutIcon} alt="wallet" />;
    case "challenge":
    case "invitation_invitee":
    case "invitation_inviter":
    case "intro_reward":
    case "mass_bonus_cash_bonus":
    case "personal_bonus":
    case "special_offer":
    case "user_accept_blogger_invite":
    case "personal_bonus_cash_bonus":
    case "bonus_cash_auto_replenish":
      return <img src={PromocodeIcon} alt="wallet" />;
    default:
      return null;
  }
}

export function getGamesTransactionIcon(status) {
  switch (status) {
    case "win":
      return <img src={WalletGameWinIcon} alt="game" />;
    case "loss":
      return <img src={WalletGameLoseIcon} alt="game" />;
    case "draw":
    default:
      return <img src={WalletGameDrawIcon} alt="game" />;
  }
}

export function getOption(label) {
  return (
    <div className="status_select">
      <div>{label}</div>
    </div>
  );
}

export function formatPhoneNumber(phone) {
  return phone.split(" ").join("").split("(").join("").split(")").join("").split("-").join("");
}

export function secondsToDHM(seconds) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const dDisplay = d > 0 ? d + "d " : "";
  const hDisplay = h > 0 ? h + "h " : "";
  const mDisplay = m > 0 ? m + "m " : "";
  return dDisplay + hDisplay + mDisplay;
}

export const joinQueries = (arr) => `${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`;

export const goBack = (history, fallbackUrl = "/admin/game-center") =>
  history.length > 1 ? history.goBack() : history.push(fallbackUrl);

export const getTournamentStageByDepthIndex = (depth_index, t) => {
  switch (depth_index) {
    case 1:
      return t("common:tournaments.final");
    case 2:
      return t("common:tournaments.semi-finals");
    case 3:
      return t("common:tournaments.quarter-finals");
    default:
      return `${t("common:tournaments.round-of")} ${Math.pow(2, depth_index)}`;
  }
};

export function getPlatformNameAndIcon(username, platformType) {
  switch (platformType.toLowerCase()) {
    case "xbox":
      return (
        <p className="platform-username-and-icon">
          {username}
          <img src={XboxSmallIcon} alt="xbox" />
        </p>
      );
    case "steam":
      return (
        <p className="platform-username-and-icon">
          {username}
          <img src={SteamSmallIcon} alt="steam" />
        </p>
      );
    default:
      return (
        <p className="platform-username-and-icon">
          {username}
          <img src={PsnSmallIcon} alt="psn" />
        </p>
      );
  }
}

export const sendGetRequest = (url, method) => {
  const options = {
    method: method,
    headers: {
      Authorization: "Token " + localStorage.getItem("token"),
      "Content-Type": "application/json",
      "Accept-Language": localStorage.language || "en"
    }
  };
  return fetch(url, options);
};

export const getCurrency = (currency, userCurrency) => {
  switch (currency) {
    case "bonus_cash":
      return " Bonus Cash";
    // case "GAMES":
    //   return " GAMES";
    case "fiat":
    default:
      return ` ${parseUserCurrency(userCurrency)}`;
  }
};

export const splitByCommas = (data) => data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const renderMyBet = (bet, currency, userCurrency) =>
  `${bet} ${currency === "bonus_cash" ? "Bonus Cash" : parseUserCurrency(userCurrency)}`;

export const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const parseUserCurrency = (currency, key = "name") => {
  if (currency) return currency[key];
  return "c.u.";
};
